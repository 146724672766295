import avatar from './Media/Images/avatar.jpg';
import Git from './Media/Images/GitHub.png';

function App() {

  function onClickResume(){
    window.open(
      "https://professoinal-portfolio.s3.us-east-2.amazonaws.com/Jonathan+Lamptey's+Resume.pdf"
      );
  }

  function onClickGitHub(){
    window.open(
      "https://github.com/nii3601"
      );
  }

  function onPorfolioClick(){
    alert("Working on this Page :)");
  }

  return (
    <div className="flex flex-col justify-center relative items-center bg-main-2 bg-cover min-h-screen">
      <div className="flex flex-col w-4/5 rounded-xl backdrop-blur-sm bg-white/50 items-center space-y-1 p-3">
        <img src={avatar} alt="avatar.jpg" className='mt-2 rounded-full border-2 border-sky-500 h-40 w-40'/>
        <div className='text-center space-y-2'>
        <p className='text-lg font-bold lg:text-xl'>Jonathan David Nii Djan Lamptey</p>
        <p className=' font-semibold lg:text-lg'>Full Stack Developer</p>
        <p className='font-medium text-sm lg:text-lg'>Bachelor of Science in Computer Eng.</p>
        {/* <p className='font-medium text-left text-xs lg:text-base'>University of Notre Dame Class of '22</p> */}
        </div>
        <div className='text-center space-y-1 pt-3 pb-3'>
        <p className='font-bold text-lg lg:text-xl underline pl-2'>About Me</p>
        <p className="break-words text-center font-semibold lg:text-lg">
          I'm a hard-core geek with a passion for building beautiful and elegant software. 
          I have participated in projects that use Python, JavaScript (especially React), Java, C++ & Flutter. 
          I use my skills to build products that make lives easier and improve the efficiency of software. 
          I specialize in Full-Stack & Backend Development.
          </p>
        </div>
        
        <div className='flex flex-row space-x-3'>
        <button onClick={onClickResume} className="bg-red-800 rounded p-2 text-white font-bold shadow-lg shadow-zinc-200 hover:scale-110">📄&nbsp;Resume</button>
        <button onClick={onPorfolioClick} className="bg-blue-800 rounded p-2 text-white font-bold shadow-lg shadow-zinc-200 hover:scale-110">👨🏾‍💻&nbsp;Portfolio</button>
        </div>

        <div className='flex flex-row pt-2'>
        <button onClick={onClickGitHub} className="bg-white rounded p-2 text-white font-bold shadow-lg shadow-zinc-200 hover:scale-110 flex flex-row items-center space-x-2">
          <img className='h-8 w-8' src={Git} alt={"Github Logo"}/>
          <p className='text-black'>Github</p>
        </button>
        </div>
        
       

      </div>
      <div className='bottom-0 absolute text-md bg-white/30 text-black lg:text-lg w-screen flex justify-center'>
          <p>Created with&nbsp;
            <a href="https://reactjs.org/" className='hover:underline hover:text-blue-500'>ReactJS</a>
            &nbsp;&&nbsp;
            <a href="https://tailwindcss.com/" className='hover:underline hover:text-blue-500'>Tailwind CSS</a>
          </p>
        </div>
    </div>
  );
}

export default App;
